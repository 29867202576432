import { useFeatureAddProductToCart } from '~/features/cart/addProductsToCart';
import { checkIsServer } from '~/shared/browserAPI/isServer';

export default defineNuxtRouteMiddleware(async () => {
  if (checkIsServer()) {
    return;
  }

  const { setCartEntityFromLocalStorageToStore } = useFeatureAddProductToCart();

  setCartEntityFromLocalStorageToStore();
});
